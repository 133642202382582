import React, { ReactElement, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import vsbl from 'vsbl'
import './styles.css'

import BlockContent from '@sanity/block-content-to-react'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'
import {
  HeroShapeOne,
  HeroShapeTwo,
  HeroShapeThree,
  HeroShapeFour,
} from './heroShapes'

export interface HeroProps {
  data: {
    text: any[]
    backgroundColor?: string
    containerSize?: string
    shapes: any[]
    spacing?: string
    heroImage: { asset: { _id: string } }
    bgImage: string
    imageColor: string
  }
}

export const Hero = ({ data }: HeroProps): ReactElement => {
  const {
    text,
    backgroundColor,
    containerSize,
    spacing,
    heroImage,
    bgImage,
    imageColor = 'primary-1',
  } = data
  const dom = useRef()
  const [className, setClass] = useState('')

  const shapesMapping: { [key: string]: ReactElement } = {
    ShapeOne: (
      <HeroShapeOne
        className={cx('abs hero-backgroundImage top right', imageColor)}
      />
    ),
    ShapeTwo: (
      <HeroShapeTwo
        className={cx('abs hero-backgroundImage top right', imageColor)}
      />
    ),
    ShapeThree: (
      <HeroShapeThree
        className={cx('abs hero-backgroundImage top right', imageColor)}
      />
    ),
    ShapeFour: (
      <HeroShapeFour
        className={cx('abs hero-backgroundImage top right', imageColor)}
      />
    ),
  }

  const ImageElement = bgImage ? shapesMapping[bgImage] : null

  return (
    <div
      ref={dom}
      className={cx('rel', 'is-visible', backgroundColor, className, spacing, {
        cw: backgroundColor === 'bcb',
        pyl: spacing === undefined,
        'cw bcb': backgroundColor === undefined,
      })}
    >
      {ImageElement}
      <div className="container--l outer mxa hero__inner f jcs ais py2 p1 al x rel">
        <div
          className={cx('x animate opacity slide-up d2 col-m-7 col-xs-24', {
            containerSize,
            mxa: !!heroImage?.asset?._id,
          })}
        >
          <BlockContent blocks={text} serializers={Serializer} />
        </div>
        {heroImage?.asset?._id && (
          <div className="x cw rel col-xs-24">
            <Image
              imageId={heroImage?.asset?._id}
              alt={''}
              raw={heroImage}
              lazy
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Hero
